import React, { Component } from "react";
import { translate } from "react-i18next";
import { detect } from "detect-browser";
import ParentPIN from "./ParentPIN";
import DisablePrivateMACInstructions from "./DisablePrivateMACInstructions";
import Details from "./Details";
import blockedIcon from "../img/circle_icon_exclamation.svg";
import unknownDeviceIcon from "../img/icon_unknown_device.svg";
import Spinner from "./Spinner";
import { checkIfCaptivePortal } from "../Utils";

class Blocked extends Component {
  constructor(props) {
    super(props);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.parentMode = "unknownDevice";
    this.state = { mode: this.parentMode };
  }

  handleModeChange(state) {
    this.props.onloadFurtherDetails().then(() => {
      if (!this.props.tokenExpired) {
        this.setState({ mode: state });
      }
    });
  }

  componentDidMount() {
    if (checkIfCaptivePortal(this.props.urlParams.cat)) {
      this.props.onloadFurtherDetails();
    }
  }

  render() {
    const { t, details, urlParams } = this.props;
    let isCaptivePortal = checkIfCaptivePortal(urlParams.cat);
    let isCaptivePortalWithPrivateMac = false;
    let isPrivateMAC = false;
    const browser = detect();

    if (details) {
      const mac = details.device.mac;
      isPrivateMAC = /^.[a26e]/.test(mac);

      // is this a private mac address
      if (isPrivateMAC && isCaptivePortal) {
        isCaptivePortalWithPrivateMac = true;
      }
    }

    if (this.state.mode === "assignDevice") {
      return (
        <ParentPIN
          details={this.props.details}
          startExpiryCountdown={this.props.startExpiryCountdown}
          onloadFurtherDetails={this.props.onloadFurtherDetails}
          loading={this.props.loading}
          onStateChange={this.handleModeChange}
          parentMode={this.parentMode}
          url={this.props.urlParams.url}
          urlParams={this.props.urlParams}
          accessType={this.props.urlParams.at}
        />
      );
    }

    if (isCaptivePortal && !details) {
      return (
        <React.Fragment>
          <img
            src={blockedIcon}
            className="setup-container-header-img"
            alt="blocked"
          />

          <div className="container-content" style={{ paddingTop: "25px" }}>
            <Spinner />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {isPrivateMAC ? (
          <img
            src={blockedIcon}
            className="setup-container-header-img"
            alt="blocked"
          />
        ) : (
          <img
            src={unknownDeviceIcon}
            className="setup-container-header-img"
            alt="blocked"
          />
        )}

        <div className="container-content">
          {isPrivateMAC ? (
            <div className="captive block-sm-margin-v">
              <h1 className="page-title">{t("captive.title")}</h1>
              <p className="block-md-margin-v">
                <strong>{t("captive.reason")}</strong>
              </p>
              <p className="block-md-margin-v">{t("captive.detection")}</p>
              <div className="details-header uppercase">NEXT STEPS</div>

              <DisablePrivateMACInstructions
                browser={browser}
                t={t}
                isCaptivePortal={isCaptivePortal}
              />

              {isCaptivePortalWithPrivateMac && (
                <p>
                  <strong>{t("captive.more-info")}</strong>
                </p>
              )}
            </div>
          ) : (
            <div>
              <h1 className="page-title">{t("unknown.title")}</h1>
              <p>{t("unknown.device")}</p>

              <button
                type="button"
                className="btn--wide block-sm-margin"
                onClick={() => this.handleModeChange("assignDevice")}
              >
                {t("buttons.assign-device")}
              </button>
            </div>
          )}
        </div>

        <Details
          urlParams={this.props.urlParams}
          onloadFurtherDetails={this.props.onloadFurtherDetails}
          details={details}
          loading={this.props.loading}
        />
      </React.Fragment>
    );
  }
}

export default translate("translations")(Blocked);
