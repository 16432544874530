import React from 'react';

const UserList = ({ users }) => {
  if (!users) {
    return null;
  }

  if (!users.length) {
    return <option>Fetching users...</option>;
  } else {
    return users.map(u => (
      <option key={u.id} id={u.id}>
        {u.name.firstName} {u.name.lastName}
      </option>
    ));
  }
}

export default UserList;