import React, { useState } from "react";
import { translate } from "react-i18next";
import Details from "./Details";
import BorrowDevice from "./BorrowDevice";
import noFilterIcon from "../img/circle_icon_no_filter.svg";
import tickIcon from "../img/circle_icon_tick.svg";
import CurrentAccessType from "./CurrentAccessType";
import AccessRequest from "./AccessRequest";

const HomeDotTools = (props) => {
  const [mode, setMode] = useState("home");
  const parentMode = "home";

  const handleModeChange = (state) => {
    props.onloadFurtherDetails().then(() => {
      if (!props.tokenExpired) {
        setMode(state);
      }
    });
  };

  if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    // Page has been refreshed, force re-navigate to home.tools to re-fetch latest access types etc
    window.href = window.location.hostname;
  }

  const { t } = props;
  const { at, monitorOnly } = props.urlParams;
  const isMonitorOnly = at && monitorOnly;

  if (mode === parentMode) {
    return (
      <>
        <img
          src={at ? tickIcon : noFilterIcon}
          className="setup-container-header-img"
          alt="home.tools"
        />
        <div className="container-content">
          {isMonitorOnly && (
            <div>
              <h1 className="page-title">{t("home.monitored-title")}</h1>
              <p>{t("home.monitored")}</p>
            </div>
          )}
          {at && !isMonitorOnly && (
            <div>
              <h1 className="page-title">{t("home.filtered")}</h1>
              <p>{t("home.reason")}</p>
              <CurrentAccessType {...props} />
            </div>
          )}
          {!at && (
            <div>
              <h1 className="page-title">{t("home.not-filtered")}</h1>
              <p>
                {t("validation.no-params")}
                <a
                  href="https://help.familyzone.com/support/home"
                  rel="noopener"
                  target="_blank"
                >
                  {t("validation.troubleshooting")}
                </a>
              </p>
            </div>
          )}
        </div>

        {at && !isMonitorOnly && (
          <>
            <button
              type="button"
              className="btn--wide block-xs-margin"
              onClick={() => handleModeChange("accessRequest")}
            >
              {t("buttons.ask-access")}
            </button>
            <button
              type="button"
              className="btn--wide block-sm-margin"
              onClick={() => handleModeChange("borrowDevice")}
            >
              {t("buttons.borrow")}
            </button>
            <Details {...props} />
          </>
        )}
        {isMonitorOnly && <Details {...props} />}
      </>
    );
  } else if (mode === "borrowDevice") {
    return (
      <BorrowDevice
        onStateChange={handleModeChange}
        parentMode={parentMode}
        {...props}
      />
    );
  } else if (mode === "accessRequest") {
    return (
      <AccessRequest
        onStateChange={handleModeChange}
        parentMode={parentMode}
        {...props}
      />
    );
  }
};

export default translate("translations")(HomeDotTools);
