import React, { Component } from "react";
import { translate } from "react-i18next";
import {
  getUserDetails,
  urlAccessRequest,
  accessTypeRequest,
} from "../Services";
import icon from "../img/circle_icon_globe.svg";

class AccessRequest extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      mode: "accessRequest",
      errors: [],
      currentAt: this.props.urlParams.at.toLowerCase(),
    };
    this.accessTypes = ["sleep", "school", "study", "play"];
    this.handleChange = this.handleChange.bind(this);
    this.onUrlAccessRequest = this.onUrlAccessRequest.bind(this);
    this.onAccessTypeRequest = this.onAccessTypeRequest.bind(this);
    this.selectAccessType = this.selectAccessType.bind(this);
    this.getUserMessage = this.getUserMessage.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;

    if(!this.props.details) {
      this.setState({ errors: [t("common.unexpected-error")] });
      this.handleChange();
    }

    // We need to know if the user is filtered in their own zone (rather than the school's zone)
    getUserDetails(
      this.props.details.primaryZoneId,
      this.props.details.user.id,
      this.props.details.accessToken
    )
      .then((userDetails) => {
        this.setState({ userDetails });
      })
      .catch(() => {
        this.setState({ errors: [t("common.unexpected-error")] });
      });
  }

  handleChange() {
    this.props.onStateChange(this.props.parentMode);
  }

  selectAccessType(e) {
    this.setState({ selected: e.currentTarget.id });
  }

  onAccessTypeRequest(e) {
    e.preventDefault();
    const { t } = this.props;

    this.setState({ errors: [], sent: "sending" });

    const request = {
      requestingUserId: this.props.details.user.id,
      zoneId: this.props.details.primaryZoneId,
      deviceId: this.props.details.device.mac,
      userMessage: this.getUserMessage(),
      accessType: this.state.selected.toUpperCase(),
    };

    accessTypeRequest(request, this.props.details.accessToken)
      .then(() => this.setState({ errors: null, sent: "accessType" }))
      .catch((error) => {
        if (error.message.includes("429")){
          this.setState({ errors: [t("common.too-many-requests")], sent: null });
        }
        else {
          this.setState({ errors: [t("common.unexpected-error")], sent: null });
        }
      });
  }

  onUrlAccessRequest(e) {
    e.preventDefault();
    const { t } = this.props;

    this.setState({ errors: [], sent: "sending" });

    const request = {
      zoneId: this.props.details.policyOwner.zoneId,
      url: this.props.urlParams.url,
      deviceId: this.props.details.device.mac,
      requestingUserId: this.props.details.user.id,
      userMessage: this.state.comments,
    };

    urlAccessRequest(request, this.props.details.accessToken)
      .then(() => this.setState({ errors: null, sent: "url" }))
      .catch((error) => {
        if (error.message.includes("429")){
          this.setState({ errors: [t("common.too-many-requests")], sent: null });
        }
        else {
          this.setState({ errors: [t("common.unexpected-error")], sent: null });
        }
      });
  }

  getUserMessage() {
    const { t } = this.props;
    const requested = `${t("ar.change-to", {
      fromAccessType: this.props.urlParams.at.toLowerCase(),
      toAccessType: this.state.selected,
    })}`;
    const message = this.state.comments
      ? `${requested} ${t("ar.message", {
          deviceOwner: this.props.details.user.name.firstName,
        })} ${this.state.comments}`
      : requested;

    return message;
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <img
          src={icon}
          className="setup-container-header-img"
          alt="{t('ar.request-access')}"
        />
        <form onSubmit={this.requestAccess}>
          <div className="container-content">
            <h1 className="page-title">{t("ar.ask")}</h1>

            {this.props.parentMode != "blocked" &&
              (<div>
                <p>{t("ar.which")}</p>
                <AccessChange t={t} state={this.state} that={this} />

                <textarea
                  rows="3"
                  className="form-input"
                  placeholder={t("ar.reason")}
                  className="text-comments"
                  onChange={(e) =>
                    this.setState({
                      comments: e.target.value,
                  })}
                ></textarea>

                <button
                  type="button"
                  id="accessTypeRequest"
                  onClick={this.onAccessTypeRequest}
                  className="btn--wide block-sm-margin"
                  disabled={this.state.sent === "sending" || !this.state.selected}
                >
                  {t("buttons.send")}
                </button>
              </div>)}

              {this.state.userDetails &&
                this.state.userDetails.protection.settings.accessTypes.PLAY
                .filtered &&
                this.props.parentMode === "blocked" && (
                  <SiteAccess t={t} url={this.props.urlParams.url} that={this} />
                )}

                <Errors errors={this.state.errors} />
              </div>

          <a href="#" className="link" onClick={this.handleChange}>
            {t("common.cancel")}
          </a>
        </form>
      </React.Fragment>
    );
  }
}

function AccessChange({ t, state, that }) {
  const accessTypes = that.accessTypes.map((type) => {
    if (
      that.state.userDetails &&
      !that.state.userDetails.protection.settings.accessTypes.PLAY.filtered &&
      that.props.isSchool &&
      (type === "study" || type === "sleep")
    ) {
      return;
    }

    // Don't allow option to choose current access type as an access request
    const onClick =
      state.currentAt !== type
        ? that.selectAccessType
        : (e) => e.preventDefault();

    return (
      <div
        id={type}
        key={type}
        className={`${type}-access-type-img block-xs-margin ${
          state.selected === type ? "active" : ""
        } ${state.currentAt === type ? "disabled" : ""}`}
        title={state.currentAt === type ? t("ar.current-at") : ""}
        onClick={onClick}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="flex-row access-type block-xs-margin">{accessTypes}</div>

      <div className="success">
        {!state.errors && state.sent === "accessType" && t("ar.success")}
      </div>
    </React.Fragment>
  );
}

function SiteAccess({ t, url, that }) {
  return (
    <React.Fragment>
      <p className="text-center block-sm-margin">
        {t("ar.access-to")} <strong>{url}</strong>
      </p>

      <textarea
        rows="3"
        className="form-input"
        placeholder={t("ar.reason")}
        className="text-comments"
        onChange={(e) =>
          that.setState({
            comments: e.target.value,
          })
        }
      ></textarea>

      <button
        type="button"
        onClick={that.onUrlAccessRequest}
        className="btn--wide block-sm-margin"
        disabled={that.state.sent === "sending"}
      >
        {t("buttons.request")}
      </button>

      <div className="success">
        {!that.state.errors && that.state.sent === "url" && t("ar.success")}
      </div>
    </React.Fragment>
  );
}

function Errors({ errors }) {
  if (!errors) {
    return null;
  }

  return errors.map((err) => (
    <div key={err} className="error">
      {err}
    </div>
  ));
}

export default translate("translations")(AccessRequest);
