import React, { Component } from "react";
import { translate } from "react-i18next";
import Blocked from "./components/Blocked";
import HomeDotTools from "./components/HomeDotTools";
import ErrorModal from "./components/ErrorModal";
import { checkIfCaptivePortal, getJsonFromUrl } from "./Utils";
import { getDetails, configReady, checkCertificateInstalled } from "./Services";
import IOSCertInstall from "./components/IOSCertInstall";
import icon from "./img/icon_lock.svg";
import Spinner from "./components/Spinner";
import ParentPIN from "./components/ParentPIN";
import InstallCertificate from "./components/InstallCertificate";
import Unknown from "./components/Unknown";

const params = getJsonFromUrl();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: "", hasCertInstalled: true };
    this.timer = 0;
    this.handleModeChange = this.handleModeChange.bind(this);
    this.loadFurtherDetails = this.loadFurtherDetails.bind(this);
    this.startExpiryCountdown = this.startExpiryCountdown.bind(this);
    this.checkCertificateInstalled = this.checkCertificateInstalled.bind(this);
    this.iosCertInstall = this.iosCertInstall.bind(this);
  }

  handleModeChange(state) {
    clearInterval(this.timer);
    this.setState({ mode: state, expiryTime: null });
    return false;
  }

  iosCertInstall(state) {
    this.setState({ mode: state });
  }

  componentDidMount() {
    if (params.uzr === "EXTERNAL") {
      // School zone
      this.loadFurtherDetails();
      this.setState({ isSchool: true });
    } else if (params.uzr === "UNKNOWN_GUEST") {
      //check for private MAC
      this.loadFurtherDetails();
    }

    let isCaptivePortal = checkIfCaptivePortal(params.cat);

    if (window.location.href.includes("install-cert")) {
      this.setState({ hasCertInstalled: false, showCert: true });
      window.localStorage.removeItem("ignoreCert");
      window.sessionStorage.removeItem("ignoreCert");
    } else if (!isCaptivePortal) {
      this.checkCertificateInstalled();
    }
  }

  checkCertificateInstalled() {
    if (params.url && params.url.startsWith("http:")) {
      // Needs updating to a smaller pixel img or smaller download
      configReady().then(() => {
        checkCertificateInstalled().catch(
          function () {
            this.setState({ hasCertInstalled: false });
          }.bind(this)
        );
      });
    }
  }

  loadFurtherDetails() {
    if (!this.state.details) {
      this.setState({ loading: true });
      return configReady().then(() => {
        return getDetails(params.st)
          .then((details) => {
            if (details) {
              this.setState({ details, loading: false });
            }
          })
          .catch((e) => {
            if (e.request && e.request.status === 401) {
              this.setState({ tokenExpired: true, loading: false });
            } else {
              this.setState({ error: true, loading: false });
            }
          });
      });
    } else {
      return Promise.resolve();
    }
  }

  startExpiryCountdown(expiryTime) {
    this.setState({ expiryTime });
    this.timer = setInterval(this.countDown.bind(this), 1000);
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    this.setState({ expiryTime: this.state.expiryTime - 1 });

    // Check if we're at zero.
    if (this.state.expiryTime == 0) {
      clearInterval(this.timer);
      this.setState({ mode: this.parentMode, expiryTime: null });
    }
  }

  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60)
      .toString()
      .padStart(2, "0");

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds).toString().padStart(2, "0");

    return `${minutes}:${seconds}`;
  }

  render() {
    const { t } = this.props;
    const showExpiryTime =
      (this.state.mode === "parentOptions" ||
        this.state.mode === "assignDevice") &&
      this.state.expiryTime;

    return (
      <div className="App">
        <div className="bordered-container">
          {!this.state.hasCertInstalled &&
            !this.state.showCert &&
            !window.localStorage.getItem("ignoreCert") && (
              <InstallCertificate
                iosCertInstall={this.iosCertInstall}
                modal={true}
              />
            )}
          <BlockedState that={this} />

          {this.state.mode !== "parentOptions" &&
            this.state.mode !== "iosCert" &&
            params.uzr !== "UNKNOWN_GUEST" &&
            params.at && (
              <div className="flex-row parent-link">
                <img src={icon} width="15px" alt="parent-pin" />
                <a
                  href="#"
                  onClick={() => this.handleModeChange("parentOptions")}
                >
                  {t("parentOptions.options")}
                </a>
              </div>
            )}
        </div>

        {showExpiryTime && (
          <div className="close-options">
            <p>
              {t("parentOptions.will-close", {
                timeLeft: this.secondsToTime(this.state.expiryTime),
              })}
            </p>
            <a
              href="#"
              onClick={() => this.setState({ mode: this.parentMode })}
            >
              {t("parentOptions.close")}
            </a>
          </div>
        )}

        {this.state.tokenExpired && (
          <ErrorModal
            msg={t("common.token-expired")}
            callback={() => this.setState({ tokenExpired: false })}
          />
        )}
        {this.state.error && (
          <ErrorModal
            msg={t("common.unexpected-error")}
            callback={() => this.setState({ error: false })}
          />
        )}
      </div>
    );
  }
}

function BlockedState({ that }) {
  const isBlocked =
    params.cat &&
    params.url &&
    params.info !== "true" &&
    params.uzr !== "UNKNOWN_GUEST";

  if (
    that.state.mode === "iosCert" ||
    window.location.href.includes("install-ios-cert")
  ) {
    return <IOSCertInstall iosCertInstall={that.iosCertInstall} modal={true} />;
  } else if (window.location.href.includes("install-cert")) {
    return (
      <InstallCertificate iosCertInstall={that.iosCertInstall} modal={false} />
    );
  }

  if (that.state.mode === "parentOptions") {
    return (
      <ParentPIN
        details={that.state.details}
        startExpiryCountdown={that.startExpiryCountdown}
        onloadFurtherDetails={that.loadFurtherDetails}
        loading={that.state.loading}
        onStateChange={that.handleModeChange}
        parentMode={isBlocked ? "blocked" : "home"}
        url={params.url}
        accessType={params.at}
        tokenExpired={that.state.tokenExpired}
        urlParams={params}
        isSchool={that.state.isSchool}
      />
    );
  }

  if (isBlocked) {
    return (
      <Blocked
        urlParams={params}
        details={that.state.details}
        onloadFurtherDetails={that.loadFurtherDetails}
        loading={that.state.loading}
        isSchool={that.state.isSchool}
        tokenExpired={that.state.tokenExpired}
      />
    );
  } else if (
    that.state.mode === "unknownDevice" ||
    params.uzr === "UNKNOWN_GUEST"
  ) {
    return (
      <Unknown
        urlParams={params}
        details={that.state.details}
        onloadFurtherDetails={that.loadFurtherDetails}
        onStateChange={that.handleModeChange}
        startExpiryCountdown={that.startExpiryCountdown}
        loading={that.state.loading}
        tokenExpired={that.state.tokenExpired}
      />
    );
  }

  return (
    <HomeDotTools
      urlParams={params}
      details={that.state.details}
      onloadFurtherDetails={that.loadFurtherDetails}
      loading={that.state.loading}
      tokenExpired={that.state.tokenExpired}
      isSchool={that.state.isSchool}
    />
  );
}

export default translate("translations")(App);
