import React, { Component } from "react";
import { translate } from "react-i18next";
import { getUsers, borrowDevice } from "../Services";
import UserList from "./UserList";
import Spinner from "./Spinner";
import icon from "../img/circle_icon_change.svg";

class BorrowDevice extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: "borrowDevice", pin: "", errors: [] };

    this.handleChange = this.handleChange.bind(this);
    this.borrowDevice = this.borrowDevice.bind(this);
    this.setPIN = this.setPIN.bind(this);
  }

  handleChange() {
    this.props.onStateChange(this.props.parentMode);
  }

  setPIN(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ pin: e.target.value, success: false });
    }
  }

  componentDidMount() {
    const { t } = this.props;

    if (!this.props.details) {
      return;
    }

    this.setState({ loading: true });
    getUsers(this.props.details.primaryZoneId, this.props.details.accessToken)
      .then((users) => {
        if (this.props.details.user) {
          // Filter out current user so can't borrow from themselves
          users = users.filter(
            (user) => user.id !== this.props.details.user.id
          );
        }
        this.setState({ users, loading: false });
      })
      .catch(() => {
        this.setState({
          errors: [t("common.unexpected-error")],
          loading: false,
        });
      });
  }

  borrowDevice(event) {
    event.preventDefault();
    const { t } = this.props;

    if (this.state.pin.length && this.state.pin.length !== 4) {
      const errors = [t("validation.pin-length")];
      this.setState({ errors });
      return;
    }

    const request = {
      deviceId: this.props.details.device.mac,
      borrowerUserId: this.state.borrowerUserId || this.state.users[0].id,
      pin: this.state.pin,
    };

    this.setState({ loading: true });
    borrowDevice(request, this.props.details.accessToken)
      .then(() =>
        this.setState({
          errors: null,
          success: true,
          loading: false,
        })
      )
      .catch((err) => {
        if (err.response.status === 400) {
          // only show invalid pin error
          this.setState({
            errors: [t("validation.invalid-pin")],
            loading: false,
          });
        } else {
          this.setState({
            errors: [t("common.unexpected-error")],
            loading: false,
          });
        }
      });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="block-md-margin-h">
        <img
          src={icon}
          className="setup-container-header-img"
          alt="{t('borrow.borrow-device')}"
        />
        <form onSubmit={this.borrowDevice}>
          <div className="container-content">
            <h1 className="page-title">{t("borrow.borrow-device")}</h1>
            <select
              id="userSelect"
              className="form-input"
              onChange={(e) =>
                this.setState({
                  borrowerUserId: e.target[e.target.selectedIndex].id,
                })
              }
            >
              <UserList users={this.state.users} />
            </select>

            <p className="block-sm-margin">{t("borrow.pin")}</p>
            <input
              type="password"
              value={this.state.pin}
              onChange={this.setPIN}
              className="form-input pin"
              id="pin"
              placeholder="PIN"
              maxLength="4"
            />

            <Errors errors={this.state.errors} />
          </div>

          <button
            type="submit"
            className="btn--wide block-xs-margin"
            disabled={
              !this.state.users || this.state.success || this.state.loading
            }
          >
            {this.state.pin ? t("borrow.borrow") : t("borrow.request-borrow")}
          </button>

          {this.state.loading && <Spinner />}

          {this.state.success && (
            <div className="success">
              {this.state.pin ? t("borrow.success") : t("borrow.request-sent")}
            </div>
          )}

          <a href="#" className="link" onClick={this.handleChange}>
            {t("common.cancel")}
          </a>
        </form>
      </div>
    );
  }
}

function Errors({ errors }) {
  if (!errors || !errors.length) {
    return null;
  }

  return errors.map((err) => (
    <div key={err} className="error">
      {err}
    </div>
  ));
}

export default translate("translations")(BorrowDevice);
