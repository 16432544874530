import React, { Component } from "react";
import { translate } from "react-i18next";
import { getUsers, assignDevice } from "../Services";
import icon from "../img/circle_icon_family.svg";
import UserList from "./UserList";

class AssignDevice extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: "assignDevice", users: [], errors: [] };

    this.handleChange = this.handleChange.bind(this);
    this.assignDevice = this.assignDevice.bind(this);
  }

  handleChange() {
    this.props.onStateChange(this.props.parentMode);
  }

  componentDidMount() {
    const { t } = this.props;
    getUsers(this.props.details.primaryZoneId, this.props.details.accessToken)
      .then((users) => this.setState({ users }))
      .catch(() => {
        this.setState({ errors: [t("common.unexpected-error")] });
      });
  }

  assignDevice(event) {
    event.preventDefault();
    const { t } = this.props;
    this.setState({ errors: [] });

    if (!this.validate.call(this)) {
      return;
    }

    const request = {
      userId: this.state.userId,
      zoneId: this.props.details.primaryZoneId,
      deviceType: this.state.type,
      deviceName: this.state.deviceName,
      accessPointId: this.props.details.accessPoint.id,
    };

    assignDevice(this.props.details.device.mac, request, this.props.parentToken)
      .then(() => {
        this.setState({ errors: null });
      })
      .catch(() => {
        this.setState({ errors: [t("common.unexpected-error")] });
      });
  }

  validate() {
    const { t } = this.props;
    let errors = [];

    // clear any displayed errors
    [...document.getElementsByClassName("field-error")].forEach((el) =>
      document.querySelector(".container-content").removeChild(el)
    );

    if (!this.state.deviceName) {
      errors.push({ id: "#deviceName", msg: t("validation.device-name") });
    }

    if (!this.state.userId) {
      errors.push({ id: "#user", msg: t("validation.device-user") });
    }

    if (!this.state.type) {
      errors.push({ id: "#type", msg: t("validation.device-type") });
    }

    if (errors.length) {
      errors.map((err) => {
        document
          .querySelector(err.id)
          .insertAdjacentHTML(
            "afterend",
            `<div class="field-error">${err.msg}</div>`
          );
      });
      return false;
    }

    return true;
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <img
          src={icon}
          className="setup-container-header-img"
          alt="{t('assignDevice.assign-device')}"
        />
        <form onSubmit={this.assignDevice}>
          <div className="container-content block-md-margin-h">
            <h1 className="page-title">{t("assignDevice.assign-device")}</h1>

            <input
              type="text"
              id="deviceName"
              value={this.state.deviceName}
              onChange={(e) => this.setState({ deviceName: e.target.value })}
              className="form-input"
              placeholder={t("assignDevice.name")}
            />

            {this.renderDeviceTypes.call(this, t)}

            <select
              id="user"
              className="form-input"
              onChange={(e) =>
                this.setState({ userId: e.target[e.target.selectedIndex].id })
              }
            >
              <option value="0">{t("assignDevice.users")}</option>
              <UserList users={this.state.users} />
            </select>

            <Errors errors={this.state.errors} />
          </div>

          <button
            type="submit"
            className="btn--wide block-xs-margin"
            disabled={!this.state.users.length || !this.state.errors}
          >
            {t("buttons.assign")}
          </button>

          <div className="success">
            {!this.state.errors && t("assignDevice.assigned")}
          </div>

          <a
            href="javascript:void(0);"
            className="link"
            onClick={this.handleChange}
          >
            {t("common.cancel")}
          </a>
        </form>
      </React.Fragment>
    );
  }

  renderDeviceTypes(t) {
    return (
      <select
        className="form-input"
        id="type"
        onChange={(e) =>
          this.setState({ type: e.target[e.target.selectedIndex].value })
        }
      >
        <option value="0">{t("assignDevice.type")}</option>
        <option value="IOS">Apple</option>
        <option value="ANDROID">Android</option>
        <option value="LAPTOP">Laptop</option>
        <option value="DESKTOP">Desktop</option>
        <option value="GAMING">Gaming</option>
        <option value="MEDIA_DEVICE">Media</option>
        <option value="OTHER">Other</option>
      </select>
    );
  }
}

function Errors({ errors }) {
  if (!errors || !errors.length) {
    return null;
  }

  return errors.map((err) => <div className="error">{err}</div>);
}

export default translate("translations")(AssignDevice);
