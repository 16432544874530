import React from 'react';

const ErrorModal = ({msg, callback}) => {
  return (<div className='modal fade opaque'>
    <div className='modal-dialog'>
      <div className='modal-content token-expired'>
        <h3>{msg}</h3>
        <button type="button" className="btn--wide block-xs-margin" 
          onClick={() => callback()}>Close
        </button>
      </div>
    </div>
  </div>);
}

export default ErrorModal;