import React, { useState, useMemo } from "react";
import { translate } from "react-i18next";
import Details from "./Details";
import CurrentAccessType from "./CurrentAccessType";
import Game from "./Game";
import AccessRequest from "./AccessRequest";
import BorrowDevice from "./BorrowDevice";
import Spinner from "./Spinner";
import { urlParser } from "../Utils";
import icon from "../img/circle_icon_family.svg";

const Blocked = (props) => {
  const [mode, setMode] = useState("blocked");
  const parentMode = "blocked";
  const { t } = props;

  const handleModeChange = (state) => {
    props.onloadFurtherDetails().then(() => {
      if (!props.tokenExpired) {
        setMode(state);
      }
    });
  };

  if (props.loading && props.isSchool) {
    return <SchoolLoading t={props.t} />;
  }

  if (mode === "borrowDevice") {
    return (
      <BorrowDevice
        onStateChange={handleModeChange}
        parentMode={parentMode}
        {...props}
      />
    );
  } else if (mode === "accessRequest") {
    return (
      <AccessRequest
        onStateChange={handleModeChange}
        parentMode={parentMode}
        {...props}
      />
    );
  } else if (mode === "game") {
    return <Game onStateChange={handleModeChange} parentMode={parentMode} />;
  }

  return (
    <>
      <img
        src={icon}
        className="setup-container-header-img blocked-img"
        alt="blocked"
      />
      <div className="container-content">
        <h1 className="blocked-page-title">{t("blocked.title")}</h1>
        <InitialText
          props={props}
          setMode={setMode}
          handleModeChange={handleModeChange}
          parentMode={parentMode}
        />
      </div>
      <button
        type="button"
        className="btn--wide block-xs-margin"
        onClick={() => handleModeChange("accessRequest")}
      >
        {t("buttons.ask-access")}
      </button>
      <button
        type="button"
        className="btn--wide block-xs-margin"
        onClick={() => handleModeChange("borrowDevice")}
      >
        {t("buttons.borrow")}
      </button>

      <Details blocked="true" {...props} />
    </>
  );
};

function InitialText({ props, setMode, handleModeChange, parentMode }) {
  const darnIt = ["Darn", "Dagnabbit", "Consarnit", "Shucks", "Dang"];
  const darnItx = useMemo(
    () => Math.floor(Math.random() * (darnIt.length - 1)),
    []
  );

  // Are we dealing with a family member, member guest of school zone
  if (props.urlParams.uzr.includes("GUEST")) {
    return (
      <>
        <p>{props.t("blocked.guest")}</p>
      </>
    );
  } else if (props.isSchool && props.details) {
    return (
      <>
        <p>
          {props.t("blocked.school-reason", {
            schoolName: props.details.policyOwner.name,
          })}{" "}
          <strong className="capitalize">
            {props.urlParams.at.toLowerCase()}
          </strong>
          .
        </p>
        <img
          src={props.details.policyOwner.logoUrl}
          className="school-logo"
          alt={props.details.policyOwner.logoUrl}
        />
      </>
    );
  }
  return (
    <>
      <p style={{ margin: "0" }}>
        {props.t("blocked.reason", {
          darnIt: darnIt[darnItx],
        })}
        <strong>{urlParser(props.urlParams.url)}</strong>{" "}
        {props.t("blocked.is-blocked")}
      </p>
      <Game onStateChange={handleModeChange} />

      <CurrentAccessType {...props} />
      <hr />
    </>
  );
}

function SchoolLoading({ t }) {
  return (
    <div>
      <img src={icon} className="setup-container-header-img" alt="blocked" />

      <div className="container-content">
        <h1 className="page-title">{t("blocked.title")}</h1>
        <div className="loading-bubble">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
}

export default translate("translations")(Blocked);
