export function getJsonFromUrl() {
  const query = location.search.substr(1);
  const result = {};
  query.split("&").forEach(function (part) {
    let item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function urlParser(url) {
  url = url.indexOf("//") !== -1 ? url.split("//")[1] : url;

  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }

  return url;
}

export function doesSupport(element, attribute) {
  // detect feature is supported
  return attribute in document.createElement(element);
}

export function checkIfCaptivePortal(cat) {
  return (
    cat === "sphirewall.application.captiveportal" || cat === "Captive Portal"
  );
}
