import React, { Component } from "react";
import { translate } from "react-i18next";
import { isNumber } from '../Utils';
import ParentOptions from './ParentOptions';
import AssignDevice from './AssignDevice';
import Spinner from "./Spinner";
import { verifyPIN } from "../Services";
import icon from "../img/circle_icon_padlock.svg";

class ParentPIN extends Component {
  constructor(props) {
    super(props);
    this.onSubmitPin = this.onSubmitPin.bind(this);
    this.onPinEntry = this.onPinEntry.bind(this);
    this.state = {};
  }

  onSubmitPin() {
    const pin = document.querySelector('#pin').value;
    this.setState({loading: true, pin});

    this.props.onloadFurtherDetails().then(() => {
      if(!this.props.details || !this.state.pin) {
        this.setState({ invalidPin: true, loading: false, errorMessage: this.props.t("validation.invalid-pin")});
        return;
      }
      verifyPIN(this.props.details.primaryZoneId, this.state.pin, this.props.details.accessToken).then((response) => {
        this.parentToken = response.accessToken;
        this.props.startExpiryCountdown(response.validitySeconds);
  
        if(this.props.parentMode === 'unknownDevice') {
          this.setState({mode: 'assignDevice', loading: false});
        } else {
          this.setState({mode: 'parentOptions', loading: false});
        }
      })
      .catch((err) => {
        const inputs = document.getElementsByTagName('input'); 
        for (let i = 0; i < inputs.length; ++i) {
          // clear pin entries
          inputs[i].value = '';
        }

        // Default the error message
        var message = this.props.t("validation.invalid-pin");
        // Extract error message from the response
        if (err.response && err.response.status == 400) {
          const errObj = err.response.data?.errors?.[0];
          if (errObj?.message) {
            message = errObj.message;
          }
        }
        this.setState({ invalidPin: true, loading: false, pin: null, errorMessage: message});
      });
    });
  }

  onPinEntry(e) {
    this.setState({loading: false});

    let pin = e.currentTarget.value

    if (!isNumber(pin)) {
      e.currentTarget.value = e.currentTarget.value.slice(0, e.currentTarget.value.length-1);
      return;
    }

    this.setState({ pin });
  }

  componentDidMount(){
    this.pin1.focus(); 
  }

  componentWillMount(){
    document.addEventListener("keydown", this.handleKeyPress.bind(this), false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress.bind(this), false);
  }

  handleKeyPress(event) {
    if(event.keyCode === 13 && this.state.pin){
      this.onSubmitPin();
    }
  }

  render() {
    const { t } = this.props;

    if(this.state.mode === 'parentOptions') {
      return <ParentOptions 
        onStateChange={this.props.onStateChange} 
        details={this.props.details} 
        accessType={this.props.accessType} 
        parentMode={this.props.parentMode}
        parentToken={this.parentToken}
        expirySeconds={this.expirySeconds}
        url={this.props.url}
        urlParams={this.props.urlParams}
        isSchool={this.props.isSchool}
      />
    }

    if(this.state.mode === 'assignDevice') {
      return <AssignDevice 
        onStateChange={this.props.onStateChange}
        details={this.props.details} 
        parentMode={this.props.parentMode} 
        urlParams={this.props.urlParams}
        parentToken={this.parentToken}
        expirySeconds={this.expirySeconds}
      />;
    }

    return (
      <React.Fragment>
        <img src={icon} className="setup-container-header-img" alt="parent PIN" />
        <div className="container-content">
          <h1 className="page-title">{t("parentOptions.enter-pin")}</h1>
        </div>

        <div>
          <input id='pin' type="password" maxLength="4" autoComplete="off" className="form-input pin-input pin1" ref={(input) => { this.pin1 = input; }}  min="0" max="9" pattern="[0-9]*" onKeyPress={this.onPinEntry}/>
        </div>

        { this.state.invalidPin && <div className="error">{this.state.errorMessage}</div> }

        <button type="button" className="btn--wide block-xs-margin" onClick={this.onSubmitPin} disabled={this.state.loading}>
          {t("buttons.next")}
        </button>

        { this.props.loading || (this.state.loading && <Spinner />)}
        
        <a href="#" className="link" onClick={() => this.props.onStateChange('app')}>
          {t("common.cancel")}
        </a>
      </React.Fragment>
    );
  }
}

export default translate("translations")(ParentPIN);
