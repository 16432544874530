import React from "react";
import cog from "../img/icon_cog.svg";

const DisablePrivateMACInstructions = ({ browser, t, isCaptivePortal }) => {
  if (!browser || browser.os === undefined) return null;

  if (browser.os.includes("Windows")) {
    return (
      <ol className="disable-mac">
        <li>{t("captive.windows-info-1")}</li>
        <li>{t("captive.windows-info-2")}</li>
        <li>{t("captive.windows-info-3")}</li>
        {returnToInstructions(isCaptivePortal)}
      </ol>
    );
  } else if (browser.os.includes("iOS") || browser.os.includes("Mac OS")) {
    return (
      <ol className="disable-mac">
        <li>{t("captive.ios-info-1")}</li>
        <li>
          {t("captive.ios-info-2a")}
          <span className="info-bubble">i</span> {t("captive.ios-info-2b")}
        </li>
        <li>{t("captive.ios-info-3")}</li>
        {returnToInstructions(isCaptivePortal)}
      </ol>
    );
  } else if (browser.os.includes("Android")) {
    return (
      <ol className="disable-mac">
        <li>{t("captive.android-1")}</li>
        <li>
          {t("captive.android-2a")} <img src={cog} width="14px" />{" "}
          {t("captive.android-2b")}
        </li>
        <li>{t("captive.android-3")}</li>
        {returnToInstructions(isCaptivePortal)}
      </ol>
    );
  } else {
    return (
      <ol className="disable-mac">
        <li>{t("captive.default-settings1")}</li>
        <li>{t("captive.default-settings2")}</li>
        {returnToInstructions(isCaptivePortal)}
      </ol>
    );
  }
};

const returnToInstructions = (isCaptivePortal) => {
  return !isCaptivePortal ? (
    <li>Return to this page to register the device</li>
  ) : (
    <li>
      Visit <strong>home.tools</strong> in your browser
    </li>
  );
};

export default DisablePrivateMACInstructions;
