import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import i18nextBE from 'i18next-xhr-backend';

i18n
  .use(i18nextBE)
  .use(reactI18nextModule)
  .init({
    lng: navigator.languages ? navigator.languages[0] : ((navigator.language || navigator.userLanguage) || 'en'),
    backend: {
      loadPath: "./212/translations/{{lng}}/common.json"
    },
    fallbackLng: 'en',

    appendNamespaceToCIMode: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },

    initImmediate: false,

    react: {
      wait: true
    }
  });


export default i18n;