import React, { Component } from "react";
import { translate } from "react-i18next";
import Spinner from "./Spinner";
class Details extends Component {
  constructor(props) {
    super(props);
    this.showMore = this.showMore.bind(this);
    this.loadFurtherDetails = this.loadFurtherDetails.bind(this);
  }

  loadFurtherDetails(e) {
    e.preventDefault();
    this.props.onloadFurtherDetails().then(() => this.showMore());
  }

  showMore() {
    const { t } = this.props;

    if (!this.props.details) {
      return;
    }

    document.querySelector("#details").classList.toggle("hidden");
    const link = document.querySelector("#moreInfo");

    link.text =
      link.text == t("common.more-info")
        ? t("common.less-info")
        : t("common.more-info");
  }

  render() {
    const { t, urlParams } = this.props;
    const details = this.props.details || {};
    const isMonitorOnly = urlParams.monitorOnly;

    if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <div className="hidden block-sm-margin-v" id="details">
          <div className="details-header">{t("common.details")}</div>
          <ul className="details">
            {this.renderDetail(
              t("common.device-name"),
              details.device && details.device.name
            )}
            {this.renderDetail(
              t("common.user"),
              details.device && details.device.user
                ? details.device.user.name.firstName +
                    " " +
                    details.device.user.name.lastName
                : details.guest ?
                    details.guest.name.firstName +
                      " " +
                      details.guest.name.lastName : details.user && details.user.name.firstName
            )}
            {details.device &&
              details.device.borrowed &&
              this.renderDetail(
                t("common.borrowed"),
                details.device.owner.name.firstName +
                  " " +
                  details.device.owner.name.lastName
              )}
            {urlParams.pn &&
              this.renderDetail(t("common.profile"), urlParams.pn)}
            {urlParams.cat &&
              !urlParams.info &&
              this.renderDetail(t("blocked.category"), urlParams.cat)}
            {urlParams.url &&
              !urlParams.info &&
              this.renderDetail(t("common.blocked-url"), urlParams.url)}
          </ul>

          {urlParams.uzr === "MEMBER_GUEST" && (
            <MemberGuestRules
              categories={urlParams.cat}
              safetyNetDeniedcategories={urlParams.sndc}
              userPolicyDeniedcategories={urlParams.updc}
              t={this.props.t}
            />
          )}

          {urlParams.uzr !== "UNKNOWN_GUEST" && !isMonitorOnly && (
            <React.Fragment>
              <div className="details-header">{t("common.options")}</div>
              <ul className="details">
                {this.props.blocked && <li>{t("blocked.request")}</li>}
                <li>
                  {t("common.not-user", {
                    firstName: details.user && details.user.name.firstName,
                  })}
                </li>
              </ul>
            </React.Fragment>
          )}
        </div>

        <a
          href="#"
          className="link more-info"
          id="moreInfo"
          onClick={this.loadFurtherDetails}
        >
          {t("common.more-info")}
        </a>
      </React.Fragment>
    );
  }

  renderDetail(label, text) {
    return (
      <li>
        <strong className="detail-label">{label}:</strong>{" "}
        {text && text.split(",").join(", ")}
      </li>
    );
  }
}

function MemberGuestRules({
  categories,
  userPolicyDeniedcategories,
  safetyNetDeniedcategories,
  t,
}) {
  const deniedAtHomeCategories = userPolicyDeniedcategories.split(",");
  const deniedThisNetworkCategories = safetyNetDeniedcategories.split(",");

  const rules = categories.split(",").map((cat) => {
    const atHome = deniedAtHomeCategories.includes(cat)
      ? t("common.blocked")
      : t("common.allowed");
    const thisNetwork = deniedThisNetworkCategories.includes(cat)
      ? t("common.blocked")
      : t("common.allowed");

    return (
      <div key={cat} className="rule flex-row">
        <div className="category">{cat}</div>
        <ul className="rules-list">
          <li>
            {t("common.at-home")}
            <strong> {atHome}</strong>
          </li>
          <li>
            {t("common.this-network")}
            <strong> {thisNetwork}</strong>
          </li>
        </ul>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className="details-header">{t("common.rules")}</div>
      <div className="rules">{rules}</div>
    </React.Fragment>
  );
}

export default translate("translations")(Details);
