import React from "react";

const CurrentAccessType = (props) => {
  const { un: upNext, unw: upIn } = props.urlParams;
  let startsAt;

  if (upIn) {
    const upNextTime = upIn.split("T")[1];
    if (!upNextTime) return null;

    const startsAtHour = upNextTime.slice(0, 2);
    const startsAtMin = upNextTime.slice(3, 5);
    const ampm = startsAtHour >= 12 ? "PM" : "AM";

    startsAt =
      startsAtHour == "00"
        ? "midnight"
        : `${
            startsAtHour > 12 ? startsAtHour - 12 : +startsAtHour
          }:${startsAtMin.padEnd(2, "0")} ${ampm}`;
  }

  const currentAt = props.urlParams.at?.toLowerCase();

  return (
    <>
      {currentAt && (
        <div className="currently">
          <div className={`${currentAt}-access-type-img active`} />
          <p>
            Currently in <strong className="capitalize">{currentAt}</strong>
          </p>
        </div>
      )}

      {startsAt && upNext && (
        <p className="up-next">
          <strong className="capitalize">{upNext.toLowerCase()}</strong> starts
          at <strong>{startsAt}</strong>
        </p>
      )}
      {!startsAt && upNext && (
        <p className="up-next">
          <strong className="capitalize">{upNext.toLowerCase()}</strong> is up
          next
        </p>
      )}
      {startsAt && !upNext && (
        <p className="up-next">
          <strong className="capitalize">{currentAt}</strong> until{" "}
          <strong>{startsAt}</strong>
        </p>
      )}
    </>
  );
};

export default CurrentAccessType;
