import axios from "axios";
import "isomorphic-fetch"

let config;
const httpClient = axios.create();
httpClient.defaults.timeout = 30000; // 30 seconds

// Due to webpack being too smart and bundling the json, we need to dynamically load the json instead otherwise it
// is always set to whatever was in config.json at the time it was built i.e. localhost
export function configReady() {
  return new Promise(function (resolve, reject) {
    if(config) {
      return resolve(config);
    }
    fetch("/212/config/config.json")
    .then(res => res.json())
    .then(data => { 
      config = data;
      httpClient.defaults.baseURL = config.fzAPIBaseURL;
  
      resolve(data);
    });
  })
}

export function borrowDevice(request, token, parentToken) {
  if(request.pin || parentToken) {
    // Borrow device
    return httpClient.post(
      `/devices/v1/${request.deviceId}/actions/borrow`,
      {
        borrowerUserId: request.borrowerUserId,
        durationType: request.durationType || 'SECONDS',
        duration: request.duration || 3600,
        borrowerPin: !parentToken ? request.pin : null
      }, getHeaders(parentToken || token)
    );
  } else {
    // Borrow request
    return httpClient.post(
      `/notifications/v1/borrow-request`,
      {
        borrowerUserId: request.borrowerUserId,
        deviceId: request.deviceId,
        userMessage: request.message
      }, getHeaders(token)
    );
  }
}

export function verifyPIN(zoneId, pin, token) {
  return httpClient.post(`/auth/v1/actions/verify-zone-admin-pin`, { zoneId, pin }, getHeaders(token)).then(res => res.data);
}

export function urlAccessRequest(request, token) {
  return httpClient.post(`/notifications/v1/url-access-request`, request, getHeaders(token));
}

export function accessTypeRequest(request, token) {
  return httpClient.post(`/notifications/v1/routine-override-access-request`, request, getHeaders(token));
}

export function endBorrow(deviceId, token) {
  return httpClient.post(`/devices/v1/${deviceId}/actions/end-borrow`, {}, getHeaders(token));
}

export function urlAccess(request, token) {
  return httpClient.post(`/zones/v1/${request.zoneId}/url-access`, request, getHeaders(token));
}
export function routineOverride(request, token) {
  return httpClient.post(`/zones/v1/${request.zoneId}/users/${request.userId}/routine-overrides`, request, getHeaders(token));
}

export function assignDevice(deviceId, request, token) {
  return httpClient.post(`/devices/v1/${deviceId}/actions/register-from-box`, request, getHeaders(token));
}

export function getProposedHosts(text, token) {
  return httpClient.get(`/misc/net/v1/actions/propose-hosts?text=${encodeURIComponent(text)}`, getHeaders(token)).then(res => res.data);
}

export function getUsers(zoneId, token) {
  return httpClient.get(`/zones/v1/${zoneId}/users?activeOnly=true`, getHeaders(token)).then(res => res.data.users);
}

export function getUserDetails(zoneId, userId, token) {
  return httpClient.get(`/zones/v2/${zoneId}/user-details/${userId}?includeProtectionSettings=true`, getHeaders(token)).then(res => res.data);
}

export function getDetails(token) {
  return httpClient.post(`/auth/v1/actions/exchange-verdict-token`, { token }).then(res => res.data);
}

export function checkCertificateInstalled() {
  return fetch(config.dnsBlockPage, {mode: "no-cors", credentials: "same-origin"});
}

function getHeaders(token) {
  return {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Authorization": `Bearer ${token}`,
    }
  };
}
