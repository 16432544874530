import React, { useState, useRef, useMemo, useEffect } from "react";
import StartAnimation from "../animations/CHARACTER_DISAPPOINT.json";
import DefaultAnimation from "../animations/CHARACTER_DISAPPOINT_KICK.json";
import BestMatesAnimation from "../animations/CHARACTER_BESTMATES_VECTOR_BLEND.json";
import JubliationAnimation from "../animations/CHARACTER_JUBILATION_VECTOR_BLEND.json";
import RideAnimation from "../animations/CHARACTER_RIDETHEPONY_VECTOR_BLEND.json";
import FlossAnimation from "../animations/CHARACTER_FLOSS_VECTOR_BLEND.json";
import Lottie from "react-lottie";
import Confetti from "react-dom-confetti";

const Game = () => {
  const animations = useMemo(
    () => [
      StartAnimation,
      DefaultAnimation,
      RideAnimation,
      BestMatesAnimation,
      JubliationAnimation,
      FlossAnimation,
    ],
    []
  );

  const [activeAnimationIdx, setActiveAnimationIdx] = useState(0);
  const [confettiFire, setConfettiFire] = useState(false);
  const isPlayingRef = useRef(true);
  const isFirstPlay = useRef(true);
  const cycleRef = useRef(2);

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: activeAnimationIdx === 7 ? 300 : 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const lottieDefaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animations[activeAnimationIdx],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeAnimationIdx === 1 && !isPlayingRef.current) {
        setActiveAnimationIdx(2);
        setActiveAnimationIdx(1);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  });

  const eventListeners = [
    {
      eventName: "complete",
      callback: () => {
        isPlayingRef.current = false;
        setActiveAnimationIdx(1);
      },
    },
  ];

  const swapAnimation = () => {
    setConfettiFire(!confettiFire);
    if (isPlayingRef.current) {
      return;
    }
    isPlayingRef.current = true;

    cycleRef.current = cycleRef.current + 1;
    if (cycleRef.current > 5) cycleRef.current = 2;
    //  const idx = Math.floor(Math.random() * (animations.length - 2) + 2);
    setActiveAnimationIdx(cycleRef.current);
    isFirstPlay.current = false;
  };

  const explode = () => {
    setConfettiFire(false);
    setConfettiFire(true);
    setActiveAnimationIdx(7);
  };

  return (
    <>
      <div
        onClick={swapAnimation}
        onDoubleClick={explode}
        className="animation-wrapper"
      >
        <Lottie
          options={lottieDefaultOptions}
          eventListeners={eventListeners}
          height={200}
          width={200}
          isClickToPauseDisabled={true}
          segments={[1000, 1000]}
        />
      </div>

      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "30%",
          border: "1px solid red, width: 50px, height: 50px ",
        }}
      >
        <Confetti active={confettiFire} config={config} />
      </div>
    </>
  );
};

export default Game;
