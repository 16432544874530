import React, { Component } from "react";
import { translate } from "react-i18next";
import { detect } from "detect-browser";
import icon from "../img/circle_icon_cert.svg";
import { doesSupport } from "../Utils";

class InstallCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = { browser: null };
    this.closeModal = this.closeModal.bind(this);
    this.iosCert = this.iosCert.bind(this);
  }

  componentDidMount() {
    this.setState({ browser: detect() });
  }

  closeModal(e) {
    // Set to ignore cert prompt for this session
    window.sessionStorage.setItem("ignoreCert", true);

    if (e.currentTarget.id === "neverRemind") {
      // Also set to ignore forever
      window.localStorage.setItem("ignoreCert", true);
    }
    this.setState({ browser: null });
  }

  iosCert(e) {
    this.closeModal(e);
    this.props.iosCertInstall("iosCert");
  }

  render() {
    const { t } = this.props;

    if (window.sessionStorage.getItem("ignoreCert")) {
      return null;
    }

    if (!this.props.modal) {
      return (
        <React.Fragment>
          <img
            src={icon}
            className="setup-container-header-img"
            alt="Install certificate"
          />

          <div className="container-content">
            <Content that={this} t={t} />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="modal fade opaque">
        <div className="modal-dialog">
          <div className="modal-content">
            <Content that={this} t={t} />
          </div>
        </div>
      </div>
    );
  }
}

function Content({ that, t }) {
  return (
    <React.Fragment>
      <h2 className="page-title">{t("certificate.title")}</h2>

      <InstallButton browser={that.state.browser} that={that} t={t} />
      {that.props.modal && (
        <React.Fragment>
          <a
            href="javascript:void(0);"
            className="remind-link"
            id="remind"
            onClick={that.closeModal}
          >
            {t("buttons.remind-later")}
          </a>
          <a
            href="javascript:void(0);"
            className="remind-link"
            id="neverRemind"
            onClick={that.closeModal}
          >
            {t("buttons.never-remind")}
          </a>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function InstallButton({ browser, that, t }) {
  if (!browser) {
    return null;
  }

  const blurb = (
    <div>
      <p>{t("certificate.install")}</p>
      <p className="block-md-margin-v">
        <strong>{t("certificate.if-dont-title")}</strong>
      </p>
      <p>{t("certificate.if-dont")}</p>
    </div>
  );

  let certificate = "ca_cert.crt";
  if (browser.os === "iOS") {
    return (
      <a
        href="javascript:void(0);"
        className="btn--wide block-md-margin-v"
        onClick={that.iosCert}
      >
        {t("buttons.install-cert")}
      </a>
    );
  } else if (browser.os === "Android OS") {
    certificate = "ca_cert.crt";
  } else {
    //desktop
    if (browser.os.includes("Windows")) {
      certificate = "Family_Zone_Certificate_Installer.exe";
    } else if (browser.os.includes("Mac OS")) {
      // iOS13+ does not so easily distinguish so iPad's falling in here
      // detect feature to help determine
      if (doesSupport("download", "a")) {
        certificate = "Family_Zone_Certificate_Installer.app.zip";
      } else {
        return (
          <a
            href="javascript:void(0);"
            className="btn--wide block-md-margin-v"
            onClick={that.iosCert}
          >
            {t("buttons.install-cert")}
          </a>
        );
      }
    } else {
      if (that.props.modal) {
        // unknown/unsupported browser type
        window.sessionStorage.setItem("ignoreCert", true);
        that.setState({ browser: null });
        return null;
      } else {
        return (
          <span>
            <p>{t("certificate.unsupported")}</p>
            <p className="block-md-margin-top">
              <strong>{t("certificate.unsupported-browser")}</strong>{" "}
              {browser.name}
            </p>
            <p>
              <strong>{t("certificate.unsupported-os")} </strong>
              {browser.os}
            </p>
          </span>
        );
      }
    }
  }

  if (that.props.modal) {
    return (
      <div>
        {blurb}{" "}
        <a
          href={certificate}
          className="btn--wide block-md-margin-v"
          id="remind"
          onClick={that.closeModal}
        >
          {t("buttons.install-cert")}
        </a>
      </div>
    );
  } else {
    return (
      <div>
        {blurb}{" "}
        <a
          href={certificate}
          className="btn--wide block-md-margin-v"
          id="remind"
        >
          {t("buttons.install-cert")}
        </a>
      </div>
    );
  }
}

export default translate("translations")(InstallCertificate);
