import React, { Component } from "react";
import { translate } from 'react-i18next';
import icon  from "../img/circle_icon_cert.svg";

class IOSCertInstall extends Component {
  constructor(props) {
    super(props);
    this.state = {browser: null};
    this.close = this.close.bind(this);
  }

  close() {
    if(this.props.modal) {
      this.props.iosCertInstall(null);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <img src={icon} className="setup-container-header-img" alt="Install certificate" />

        <div className="container-content">
          <h1 className="page-title">{t('certificate.ios-title')}</h1>

          <p>{t('certificate.ios-install')}</p>

          <p className='block-md-margin-top'><strong>{t('certificate.ios-step1')}</strong></p>
          <p>{t('certificate.ios-step1-install')}</p>
          
          <a href="ca_cert.pem" className="btn--wide block-md-margin-v" id='remind' onClick={this.close}>{t('buttons.install-cert')}</a>

          <p><strong>{t('certificate.ios-step2')}</strong></p>
          <p>{t('certificate.ios-step2-install')}</p>
        </div>
      </React.Fragment>
    );
  }
}

export default translate('translations')(IOSCertInstall);
