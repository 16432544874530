import React, { Component } from "react";
import { translate } from "react-i18next";
import UserList from "./UserList";
import Spinner from "./Spinner";
import {
  getUsers,
  getUserDetails,
  borrowDevice,
  endBorrow,
  routineOverride,
  urlAccess,
  getProposedHosts,
} from "../Services";
import icon from "../img/icon_lock_grey.svg";

class ParentOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAt: this.props.accessType.toLowerCase(),
      selectedAccess: [],
    };
    this.accessTypes = ["sleep", "school", "study", "play"];
    this.endBorrowing = this.endBorrowing.bind(this);
    this.quickTimeChange = this.quickTimeChange.bind(this);
    this.addSelectedAccess = this.addSelectedAccess.bind(this);
    this.proposedHosts = this.proposedHosts.bind(this);
    this.loanDevice = this.loanDevice.bind(this);
    this.selectAccessType = this.selectAccessType.bind(this);
    this.actionSuccess = this.actionSuccess.bind(this);
    this.actionFailure = this.actionFailure.bind(this);
  }

  componentDidMount() {
    getUsers(this.props.details.primaryZoneId, this.props.details.accessToken)
      .then((users) => {
        if (this.props.details.user) {
          // Filter out current user so can't borrow from themselves
          users = users.filter(
            (user) => user.id !== this.props.details.user.id
          );

          // We need to know if the user is filtered in their own zone (rather than the school's zone)
          getUserDetails(
            this.props.details.primaryZoneId,
            this.props.details.user.id,
            this.props.details.accessToken
          ).then((userDetails) => {
            this.setState({ users, userDetails });
          });
        }
      })
      .catch(this.actionFailure);
  }

  selectAccessType(e) {
    // display warning if kid has school managed school time
    if (this.props.details && this.props.details.policyOwner.externalPolicy) {
      this.setState({
        displaySchoolTimeWarning: true,
        accessType: e.currentTarget.id,
      });
    } else {
      this.setState({
        displayDurationSelector: true,
        accessType: e.currentTarget.id,
      });
    }
  }

  actionSuccess() {
    this.setState({
      errors: null,
      loading: false,
      actionComplete: true,
    });
  }

  actionFailure() {
    this.setState({
      errors: [this.props.t("common.unexpected-error")],
      loading: false,
      displayProposedHosts: false,
    });
  }

  quickTimeChange(duration) {
    const request = {
      zoneId: this.props.details.primaryZoneId,
      userId: this.props.details.device.user.id,
      accessType: this.state.accessType.toUpperCase(),
      durationType: duration ? "SECONDS" : "REMAINING_DAY",
      duration: duration || null,
    };

    this.setState({ errors: null, loading: true });
    routineOverride(request, this.props.parentToken)
      .then(this.actionSuccess)
      .catch(this.actionSuccess);
  }

  endBorrowing() {
    this.setState({ errors: null, loading: true });
    endBorrow(this.props.details.device.mac, this.props.parentToken)
      .then(() => {
        this.props.details.device.borrowed = false;
      })
      .then(this.actionSuccess)
      .catch(this.actionFailure);
  }

  proposedHosts(type) {
    this.setState({ errors: null, loading: true });
    getProposedHosts(this.props.url, this.props.details.accessToken)
      .then(({ proposedHosts }) => {
        if (proposedHosts.length) {
          this.setState({
            displayProposedHosts: true,
            proposedHosts,
            proposedHost: proposedHosts[0],
            type,
            loading: false,
          });
        } else {
          this.addSelectedAccess(type);
        }
      })
      .catch(this.actionFailure);
  }

  addSelectedAccess(type) {
    const selectedAccess = this.state.selectedAccess || [];

    const index = selectedAccess.indexOf(type);
    if (index > -1) {
      // Remove if already added as user is toggling on/off
      selectedAccess.splice(index, 1);
    } else {
      selectedAccess.push(type);
    }

    const updates = this.accessTypes.map((type) => {
      return {
        accessType: type.toUpperCase(),
        action: this.state.selectedAccess.includes(type)
          ? "WHITELIST"
          : "BLACKLIST",
      };
    });

    const request = {
      zoneId: this.props.details.primaryZoneId,
      host: this.state.proposedHost || this.props.url,
      groupProfileIds: [this.props.details.groupProfile.id],
      updates: updates,
    };

    this.setState({ errors: null, loading: true, displayProposedHosts: false });
    urlAccess(request, this.props.parentToken)
      .then(() => this.setState({ proposedHost: null, loading: false }))
      .catch(this.actionFailure);
  }

  loanDevice(duration) {
    const request = {
      deviceId: this.props.details.device.mac,
      borrowerUserId: this.state.borrowerUserId || this.state.users[0].id,
      duration: duration,
      durationType: duration ? "SECONDS" : "REMAINING_DAY",
    };

    this.setState({ errors: null, loading: true });
    borrowDevice(request, null, this.props.parentToken)
      .then(this.actionSuccess)
      .catch(this.actionFailure);
  }

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyPress.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handleKeyPress.bind(this),
      false
    );
  }

  handleKeyPress(event) {
    if (event.keyCode === 27) {
      this.setState({
        displayDurationSelector: false,
        displayProposedHosts: false,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="modal fade opaque">
            {" "}
            <Spinner />{" "}
          </div>
        )}
        {this.state.actionComplete && <ActionComplete t={t} that={this} />}

        {this.state.displaySchoolTimeWarning && (
          <SchoolTimeWarning t={t} that={this} />
        )}

        {this.state.displayDurationSelector && (
          <DurationSelector t={t} that={this} />
        )}
        {this.state.displayProposedHosts && <ProposedHosts t={t} that={this} />}

        <img
          src={icon}
          className="setup-container-header-img"
          alt="parent PIN"
        />
        <div className="container-content">
          <h1 className="page-title">{t("parentOptions.options")}</h1>
          <p className="block-md-padding-h">
            {t("parentOptions.swap", {
              firstName: this.props.details.device.user.name.firstName,
            })}
          </p>
        </div>

        {!(
          this.state.userDetails &&
          !this.state.userDetails.protection.settings.accessTypes.PLAY
            .filtered &&
          !this.props.isSchool
        ) && <AccessChange t={t} that={this} />}

        {this.props.parentMode === "blocked" &&
          this.state.userDetails &&
          this.state.userDetails.protection.settings.accessTypes.PLAY
            .filtered && <SiteAccess t={t} url={this.props.url} that={this} />}

        <Borrowing t={t} that={this} />

        <div className="error block-sm-margin">{this.state.errors}</div>
      </React.Fragment>
    );
  }
}

function ActionComplete({ t, that }) {
  return (
    <div className="modal fade opaque">
      <div className="modal-dialog">
        <div className="modal-content">
          <h3>{t("parentOptions.successful")}</h3>
          <p>{t("parentOptions.take-time")}</p>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() => that.setState({ actionComplete: false })}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

function DurationSelector({ t, that }) {
  return (
    <div
      className="modal fade opaque"
      onClick={() => that.setState({ displayDurationSelector: false })}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {that.state.loaning ? (
            <h3>{t("parentOptions.loan-for")}</h3>
          ) : (
            <h3>
              {t("parentOptions.change-to", {
                accessType: that.state.accessType,
              })}
            </h3>
          )}

          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() =>
              that.state.loaning
                ? that.loanDevice(900)
                : that.quickTimeChange(900)
            }
          >
            {t("parentOptions.15")}
          </button>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() =>
              that.state.loaning
                ? that.loanDevice(1800)
                : that.quickTimeChange(1800)
            }
          >
            {t("parentOptions.30")}
          </button>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() =>
              that.state.loaning
                ? that.loanDevice(3600)
                : that.quickTimeChange(3600)
            }
          >
            {t("parentOptions.60")}
          </button>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() =>
              that.state.loaning ? that.loanDevice() : that.quickTimeChange()
            }
          >
            {t("parentOptions.rest")}
          </button>
        </div>
      </div>
    </div>
  );
}

function SchoolTimeWarning({ t, that }) {
  const displayDurationSelector = function () {
    that.setState({
      displayDurationSelector: true,
      displaySchoolTimeWarning: false,
    });
  };

  const decline = function () {
    that.setState({ displaySchoolTimeWarning: false, accessType: null });
  };

  return (
    <div className="modal fade opaque">
      <div className="modal-dialog">
        <div className="modal-content token-expired">
          <h3>{t("validation.school-warn")}</h3>
          <p>
            {t("validation.school-warn-text", {
              school: that.props.details.policyOwner.name,
              firstName: that.props.details.device.user.name.firstName,
            })}
          </p>
          <p>{t("validation.proceed")}</p>
          <div>
            <button
              type="button"
              className="btn--wide block-xs-margin"
              onClick={() => displayDurationSelector()}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn--wide block-xs-margin"
              onClick={() => decline()}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProposedHosts({ t, that }) {
  const hosts = that.state.proposedHosts.map((url, i) => {
    if (!i) {
      return (
        <div key={url}>
          <input
            type="radio"
            id="manageAll"
            name="group-options"
            defaultChecked
            onClick={() => that.setState({ proposedHost: url })}
          />
          <label className="fz-checkbox-label" htmlFor="manageAll">
            {" "}
            {t("parentOptions.manage-all")} <strong>{url}</strong>{" "}
            {t("parentOptions.recommended")}
          </label>
        </div>
      );
    }
    return (
      <div key={url}>
        <input
          type="radio"
          id="manageOnly"
          name="group-options"
          onClick={() => that.setState({ proposedHost: url })}
        />
        <label className="fz-checkbox-label" htmlFor="manageOnly">
          {" "}
          {t("parentOptions.manage-only")} <strong>{url}</strong>{" "}
          {t("parentOptions.advanced")}
        </label>
      </div>
    );
  });

  return (
    <div className="modal fade opaque">
      <div className="modal-dialog">
        <div className="modal-content">
          <h3>{t("parentOptions.review")}</h3>
          <section className="fz-checkbox">{hosts}</section>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() => that.addSelectedAccess(that.state.type)}
          >
            {t("buttons.add")}
          </button>
          <div className="block-xs-margin">
            <a
              href="#"
              onClick={() => that.setState({ displayProposedHosts: false })}
            >
              {t("common.cancel")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function AccessChange({ t, that }) {
  const accessTypes = that.accessTypes.map((type) => {
    if (
      that.state.userDetails &&
      !that.state.userDetails.protection.settings.accessTypes.PLAY.filtered &&
      that.props.isSchool &&
      (type === "study" || type === "sleep")
    ) {
      return;
    }

    // Don't allow option to choose current access type as an access request
    const onClick =
      that.state.currentAt !== type
        ? that.selectAccessType
        : (e) => e.preventDefault();

    return (
      <div
        id={type}
        key={type}
        className={`${type}-access-type-img block-xs-margin ${
          that.state.accessType === type ? "active" : ""
        } ${that.state.currentAt === type ? "disabled" : ""}`}
        onClick={onClick}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="details-header uppercase">
        {t("ar.swap", {
          firstName: that.props.details.device.user.name.firstName,
        })}
      </div>

      <div className="flex-row access-type justify-left block-sm-margin-v block-md-padding-h">
        {accessTypes}
      </div>
    </React.Fragment>
  );
}

function SiteAccess({ t, url, that }) {
  const accessTypes = that.accessTypes.map((type) => {
    return (
      <div
        id={type}
        key={type}
        className={`${type}-access-type-img block-xs-margin ${
          that.state.selectedAccess && that.state.selectedAccess.includes(type)
            ? "active"
            : ""
        }`}
        onClick={() => that.proposedHosts(type)}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="details-header">{t("ar.site-access")}</div>
      <p className="text-left block-sm-margin">
        {t("parentOptions.site-access")}
        <strong>{url}</strong>
        {t("parentOptions.with-profile", {
          profileName: that.props.details.groupProfile.name,
        })}
      </p>
      <p className="text-left block-sm-margin">{t("parentOptions.allow")}</p>

      <div className="flex-row access-type justify-left block-xs-margin">
        {accessTypes}
      </div>
    </React.Fragment>
  );
}

function Borrowing({ t, that }) {
  const device = that.props.details.device;

  let time = device.borrowEndTime;
  if (device.borrowed) {
    const endTime = new Date(device.borrowEndTime);
    time = `${endTime.getHours()}:${endTime
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  return (
    <React.Fragment>
      <div className="details-header">{t("parentOptions.borrowing")}</div>

      {device.borrowed && (
        <React.Fragment>
          <p className="text-left block-sm-margin">
            {t("parentOptions.loaned", {
              borrower: `${device.user.name.firstName} ${device.user.name.lastName}`,
              time,
            })}
          </p>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={that.endBorrowing}
          >
            {t("buttons.cancel-loan")}
          </button>
        </React.Fragment>
      )}

      {!device.borrowed && (
        <React.Fragment>
          <p className="text-left block-sm-margin block-sm-padding-h">
            {t("parentOptions.loan")}
          </p>
          <select
            id="userSelect"
            className="form-input borrower-list"
            onChange={(e) =>
              that.setState({
                borrowerUserId: e.target[e.target.selectedIndex].id,
              })
            }
          >
            <UserList users={that.state.users} />
          </select>
          <button
            type="button"
            className="btn--wide block-xs-margin"
            onClick={() =>
              that.setState({ displayDurationSelector: true, loaning: true })
            }
          >
            {t("buttons.loan")}
          </button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default translate("translations")(ParentOptions);
